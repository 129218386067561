import React, { useState, useRef } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import HamburgerMenu from '../Components/HamburgerMenu';
import LogoImage from '../Assets/logo.png'; // Update this path to your actual logo image
import EnrollmentImage from '../Assets/enrollment.jpeg'; // Update path if needed
import { FaChalkboardTeacher, FaBook, FaMicroscope, FaChurch, FaLanguage, FaUserTie } from 'react-icons/fa'; // Example icons from react-icons
import RequirementsImage from '../Assets/requirements.jpeg'; // Replace this with the path to your actual image
import Uniform1 from '../Assets/uniform1.jpeg';
import Uniform2 from '../Assets/uniform2.jpeg';
import Uniform3 from '../Assets/uniform3.jpeg';
import Uniform4 from '../Assets/uniform4.jpeg';
import Uniform5 from '../Assets/uniform5.jpeg';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';

const Enrollment = () => {
  const { scrollY } = useViewportScroll();
  const [showModal, setShowModal] = useState(false);


  const feesRef = useRef(null);
  const uniformsRef = useRef(null);
  const requirementsRef = useRef(null);
  const chooseusRef = useRef(null);
  const enrollmentRef = useRef(null);


  // Fade-out effect for the background section
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };


  return (
    <div>
      {/* Top Navbar with Logo and Hamburger Menu */}
      <div className="bg-[#BB005A] h-[100px] flex items-center justify-between px-8 z-10 relative">
        {/* Logo with Link */}
        <Link to="/">
          <img src={LogoImage} alt="Logo" className="h-12" />
        </Link>
        {/* Hamburger Menu */}
        <div className="mb-16">
          <HamburgerMenu />
        </div>
      </div>

      {/* Background Section with Text Overlay */}
      <motion.div
        className="h-[455px] mt-[7%] fixed top-0 left-0 w-full flex items-end justify-start bg-cover bg-center text-white p-8 z-0"
        style={{
          backgroundImage: `url(${EnrollmentImage})`,
          opacity,
        }}
      >
        {/* Overlay for opacity */}
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Content */}
        <div className="relative max-w-md p-4 rounded">
          <h1 className="text-4xl font-bold mb-4">Enrolment</h1>
          <p className="text-lg">
            Moving to a new school can be both an exciting and challenging time for students and parents.
          </p>
        </div>
      </motion.div>

      {/* Spacer to ensure content scrolls over background */}
      <div className="h-[450px]"></div>

      {/* Scroll Transition Section */}
      <div className="relative py-16 px-8 text-white bg-gradient-to-r from-[#BB005A] to-[#BB005A] flex flex-col items-center z-5">
        {/* Background spiral pattern */}
        <div
          className="absolute inset-0 bg-cover opacity-25"
          style={{
            backgroundImage: `radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent 70%), 
            radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.1), transparent 70%), 
            radial-gradient(circle at 70% 70%, rgba(255, 255, 255, 0.1), transparent 70%)`,
            backgroundSize: "100px 100px",
          }}
        ></div>

        {/* Content */}
        <div id="enrollment" className="relative max-w-2xl text-center space-y-6">
          <h2 className="text-3xl font-bold">Make a Confident Start</h2>
          <p className="text-lg">
            The team at <strong>Oxford Study Center</strong> knows that moving to a new school is both an exciting and important step in a student's life. Our community is committed to making every new student feel welcome and supported as they settle into their new environment.
          </p>
          <p className="text-lg">
            Starting with a comprehensive orientation program, <strong>Oxford Study Center</strong> ensures that students quickly feel part of the school community. From meeting friendly peers and staff to exploring new subjects and activities, each step is designed to build confidence and excitement for the journey ahead.
          </p>
          <p className="text-lg">
            Whether students are moving from another city or just a different school, our transition program is tailored to make them feel at home, supported by a vibrant community that values every individual’s growth and happiness.
          </p>
          <p className="text-lg">
  <a href="https://osc.kahwani.co.zw/online_admission" target="_blank" rel="noopener noreferrer" className=" underline hover:text-[#a1004f]">
    Click here for the online admission form
  </a>
</p>
        </div>
      </div>
      <div id="why-choose-us" className="bg-white py-16 px-6">
        {/* Heading */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-[#BB005A]">Why Choose Us</h2>
          <p className="text-lg text-gray-600 mt-2">Our commitment to excellence, community, and holistic development.</p>
        </div>

        {/* Feature Grid with Staggered Animation */}
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 text-center">
          {/* Health Student to Teacher Ratio */}
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            viewport={{ once: true }}
          >
            <FaChalkboardTeacher className="text-8xl text-black mb-4" />
            <p className="text-xl font-semibold text-[#BB005A]">Healthy Student to Teacher Ratio</p>
            <p className="text-gray-600 mt-2">We ensure that each student gets personalized attention to thrive in a supportive environment.</p>
          </motion.div>

          {/* Christ-Centered Learning */}
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
            viewport={{ once: true }}
          >
            <FaChurch className="text-8xl text-black mb-4" />
            <p className="text-xl font-semibold text-[#BB005A]">Christ-Centered Learning</p>
            <p className="text-gray-600 mt-2">Our curriculum is rooted in faith, fostering spiritual growth and moral integrity.</p>
          </motion.div>

          {/* Multi-language Support */}
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
            viewport={{ once: true }}
          >
            <FaLanguage className="text-8xl text-black mb-4" />
            <p className="text-xl font-semibold text-[#BB005A]">Multi-language Support</p>
            <p className="text-gray-600 mt-2">We embrace diversity by offering support in multiple languages, making education accessible to all.</p>
          </motion.div>

          {/* Highly Qualified Teaching Staff */}
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.6 }}
            viewport={{ once: true }}
          >
            <FaUserTie className="text-8xl text-black mb-4" />
            <p className="text-xl font-semibold text-[#BB005A]">Highly Qualified Teaching Staff</p>
            <p className="text-gray-600 mt-2">Our teachers are certified professionals, dedicated to nurturing students' academic and personal growth.</p>
          </motion.div>
        </div>
      </div>
      <div id="requirements" className="bg-white py-16 px-6">
        {/* Section Container */}
        <motion.div
          className="max-w-6xl mx-auto flex flex-col md:flex-row items-center"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          {/* Image Section */}
          <div className="flex-shrink-0 mb-6 md:mb-0 md:mr-8 w-full md:w-1/2">
            <img src={RequirementsImage} alt="Requirements" className="rounded-lg shadow-lg w-full h-auto" />
          </div>

          {/* Text Content */}
          <div className="w-full md:w-1/2">
            <h2 className="text-3xl font-bold text-[#BB005A] mb-4">REQUIREMENTS</h2>
            <hr className="border-t-2 border-[#BB005A] w-16 mb-6" />
            <ul className="list-none text-gray-800 space-y-4">
              <motion.li
                className="pb-4 border-b border-gray-300"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                viewport={{ once: true }}
              >
                » Completed Application Form (available at school)
              </motion.li>
              <motion.li
                className="pb-4 border-b border-gray-300"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
              >
                » Result slip
              </motion.li>
              <motion.li
                className="pb-4 border-b border-gray-300"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                viewport={{ once: true }}
              >
                » Photocopy of birth certificate
              </motion.li>
              <motion.li
                className="pb-4 border-b border-gray-300"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                viewport={{ once: true }}
              >
                » Transfer/Clearance from the previous school
              </motion.li>
            </ul>
          </div>
        </motion.div>
      </div>
      <div id="fees" className="bg-white py-16 px-6">
        <div className="max-w-6xl mx-auto">
          {/* Disclaimer */}
          <p className="text-center text-sm text-gray-600 mb-8">
            * Fees are subject to change at any time. Please visit the school for any clarification.
          </p>

          {/* Fees Tables */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Non-Sciences Fees */}
            <div>
              <div className="flex items-center mb-4">
                <FaBook className="text-4xl text-[#BB005A] mr-4" />
                <h2 className="text-2xl font-bold text-[#BB005A]">SCHOOL FEES: NON SCIENCES</h2>
              </div>
              <hr className="border-t-2 border-[#BB005A] mb-6" />
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr>
                    <th className="border border-[#BB005A] px-4 py-2 bg-[#BB005A] text-white">ITEM</th>
                    <th className="border border-[#BB005A] px-4 py-2 bg-[#BB005A] text-white">PRICE IN USD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Registration Fees</td>
                    <td className="border border-[#BB005A] px-4 py-2">$50 Once off payment</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Book Levy</td>
                    <td className="border border-[#BB005A] px-4 py-2">$45 Once off payment</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Medical Aid</td>
                    <td className="border border-[#BB005A] px-4 py-2">$16 Per term</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Tuition Fees</td>
                    <td className="border border-[#BB005A] px-4 py-2">$180 Per term</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Levy</td>
                    <td className="border border-[#BB005A] px-4 py-2">$75 Per term</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Practical Fee</td>
                    <td className="border border-[#BB005A] px-4 py-2">$20 Per term</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Sciences Fees */}
            <div>
              <div className="flex items-center mb-4">
                <FaMicroscope className="text-4xl text-[#BB005A] mr-4" />
                <h2 className="text-2xl font-bold text-[#BB005A]">SCHOOL FEES: SCIENCES</h2>
              </div>
              <hr className="border-t-2 border-[#BB005A] mb-6" />
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr>
                    <th className="border border-[#BB005A] px-4 py-2 bg-[#BB005A] text-white">ITEM</th>
                    <th className="border border-[#BB005A] px-4 py-2 bg-[#BB005A] text-white">PRICE IN USD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Registration Fees</td>
                    <td className="border border-[#BB005A] px-4 py-2">$50 Once off payment</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Book Levy</td>
                    <td className="border border-[#BB005A] px-4 py-2">$45 Once off payment</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Medical Aid</td>
                    <td className="border border-[#BB005A] px-4 py-2">$16 Per term</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Tuition Fees</td>
                    <td className="border border-[#BB005A] px-4 py-2">$300 Per term</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Levy</td>
                    <td className="border border-[#BB005A] px-4 py-2">$75 Per term</td>
                  </tr>
                  <tr>
                    <td className="border border-[#BB005A] px-4 py-2">Practical Fee</td>
                    <td className="border border-[#BB005A] px-4 py-2">$20 Per term</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div id="uniforms" className="bg-gray-300 py-16 px-6 relative z-10">
        {/* Header and Disclaimer */}
        <div className="max-w-6xl mx-auto text-center mb-8 z-10">
          <h2 className="text-4xl font-bold text-[#BB005A]">School Uniforms</h2>
          <p className="text-gray-600 italic mt-2">
            All school uniforms are to be purchased at{' '}
            <a
              href="https://schoolritestationeries.co.zw/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#BB005A] underline hover:text-[#a1004f]"
            >
              Schoolrite Stationery
            </a>
            . Please contact the school for any clarification on prices and availability.
          </p>
        </div>

        {/* Uniforms Photo Album Layout */}
        <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Top left photo */}
          <img src={Uniform1} alt="Uniform 1" className="w-full h-64 object-cover rounded-lg shadow-lg" />

          {/* Middle top large photo */}
          <img src={Uniform2} alt="Uniform 2" className="w-full h-80 md:col-span-2 object-cover rounded-lg shadow-lg" />

          {/* Bottom left */}
          <img src={Uniform3} alt="Uniform 3" className="w-full h-64 object-cover rounded-lg shadow-lg" />

          {/* Middle bottom small photo */}
          <img src={Uniform4} alt="Uniform 4" className="w-full h-48 object-cover rounded-lg shadow-lg" />

          {/* Bottom right photo */}
          <img src={Uniform5} alt="Uniform 5" className="w-full h-64 object-cover rounded-lg shadow-lg" />
        </div>
      </div>
      <div id="stationery-requirements" className="bg-white py-16 px-6">
        <div className="max-w-6xl mx-auto">
          {/* Header */}
          <div className="text-center mb-8">
            <h2 className="text-4xl font-bold text-[#BB005A]">Stationery Requirements</h2>
            <hr className="border-t-2 border-[#BB005A] w-16 mx-auto mt-4" />
          </div>

          {/* Content */}
          <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
            {/* Icon or Graphic */}
            <div className="w-full md:w-1/3 flex justify-center">
              <div className="p-8 rounded-full bg-[#BB005A]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-16 h-16"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12.75v6.75c0 1.5-1.5 2.25-3 2.25H7.5c-1.5 0-3-.75-3-2.25V4.5c0-1.5 1.5-2.25 3-2.25h6.75m5.25 3h-7.5c-1.5 0-2.25-.75-2.25-2.25V2.25L19.5 9z"
                  />
                </svg>
              </div>
            </div>

            {/* Text Content */}
            <div className="w-full md:w-2/3">
              <ul className="list-disc space-y-4 pl-5 text-gray-800">
                <li>2 quire and 1 quire by number of subjects.</li>
                <li>1 ream bond paper per year for forms 1, 2, 3, and 5.</li>
                <li>
                  2 reams bond papers per year for forms 4 & 6, Mathematical set,
                  Scientific calculator for Maths learners, 2 paper pouches.
                </li>
                <li>
                  3 graph books per term, laptop (optional), ledger, journal, and cash
                  book for Accounts students.
                </li>
                <li>
                  Plastic flat files by number of subjects, 72 pages by the number of
                  subjects.
                </li>
                <li>
                  Drawing board and engineering set for all Technical Graphics
                  students.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Enrollment;
