// src/components/ContactUs.js
import React from 'react';
import { Link } from 'react-router-dom';
import BackgroundImage from '../Assets/aboutus.jpeg'; // Update the path to your actual background image

const Footer = () => {
  return (
    <div
      className="relative p-10 text-white"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Overlay with increased opacity for faded effect */}
      <div className="absolute inset-0 bg-black opacity-80"></div>

      <div className="relative z-10 flex flex-col md:flex-row md:justify-between items-start">
        {/* Left Side: Contact Information */}
        <div className="md:w-1/3 space-y-6">
          <h2 className="text-4xl font-bold mb-6 text-[#BB005A]">Contact Us</h2>

          {/* Phone Numbers */}
          <div>
            <h3 className="text-2xl font-semibold mb-2 text-[#BB005A]">Phone</h3>
            <p className="text-lg">+263 24 2216757</p>
          </div>

          {/* Emails */}
          <div>
            <h3 className="text-2xl font-semibold mb-2 text-[#BB005A]">Email</h3>
            <p className="text-lg">info@osc.co.zw</p>
            <p className="text-lg">oscschools@gmail.com</p>
          </div>

          {/* Address */}
          <div>
            <h3 className="text-2xl font-semibold mb-2 text-[#BB005A]">Address</h3>
            <p className="text-lg">
              4048 3rd Street <br />
              Dzivarasekwa 1, Harare
            </p>
          </div>
        </div>

        {/* Center: Quick Links */}
        <div className="md:w-1/3 mt-10 md:mt-0 space-y-6">
          <h2 className="text-4xl font-bold mb-6 text-[#BB005A]">Quick Links</h2>
          <ul className="space-y-4">
            <li>
              <Link to="/" className="text-lg text-white hover:text-[#BB005A] transition-colors">
                Home
              </Link>
            </li>
            <li>
              <Link to="/aboutus" className="text-lg text-white hover:text-[#BB005A] transition-colors">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/enrollment" className="text-lg text-white hover:text-[#BB005A] transition-colors">
                Enrollment
              </Link>
            </li>
            <li>
              <Link to="/contactus" className="text-lg text-white hover:text-[#BB005A] transition-colors">
                Contact Us
              </Link>
            </li>
            <li>
              <p className="text-lg">
                <a href="https://osc.kahwani.co.zw/online_admission" target="_blank" rel="noopener noreferrer" className="text-lg text-white hover:text-[#BB005A] transition-colors">
                online admission 
                </a>
              </p>
            </li>
          </ul>
        </div>

        {/* Right Side: Map with increased width */}
        <div className="md:w-2/3 mt-10 md:mt-0">
          <iframe
            className="w-full h-80 md:h-[400px] rounded-lg shadow-lg" // Adjusted height for better appearance
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3798.7658236218476!2d30.928949975174465!3d-17.80269958315699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDQ4JzA5LjciUyAzMMKwNTUnNTMuNSJF!5e0!3m2!1sen!2szw!4v1730663355041!5m2!1sen!2szw"
            allowFullScreen=""
            loading="lazy"
            title="Oxford Study Center Location"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Footer;
